<template>
  <div class="row_contain" style="padding-top: 48px">
    <van-nav-bar
      :fixed="fixed"
      title="积分明细"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-empty description="暂无数据" v-if="loadStart" />
    <van-list
      v-else
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      :immediate-check="immediate"
      @load="onLoad"
    >
      <div v-for="(item, key) in list" :key="key" style="margin-bottom: 10px">
        <div class="van-div">
          <div>日期:</div>
          <div>{{ item.created_at }}</div>
        </div>
        <div class="van-div">
          <div>操作说明:</div>
          <div style="flex: 0.8">{{ item.msg }}</div>
        </div>
        <div class="van-div">
          <div>{{ item.type == "1" ? "充值积分" : "消费积分" }}:</div>
          <div>{{ item.operation_money }}</div>
        </div>
        <div class="van-div" style="border: none">
          <div>最新总积分:</div>
          <div>{{ item.latest_money }}</div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loadStart: true,
      immediate: false,
      fixed: true,
      loading: false,
      finished: true,
      page: 1,
      list: [],
      user_id: JSON.parse(localStorage.getItem('userInfo')).user_id || '0'
    }
  },
  created () {
    // user/get_recharge
    this.get_index('加载')
  },
  methods: {
    async get_index (start) {
      let res = await this.$http.get('user/get_recharge', {
        params: {
          user_id: this.user_id,
          page: this.page,
        }
      })
      if (start == '加载') {
        this.list = res.data.data;
        console.log('as', this.list, res.data.data)
      }
      if (res.data.data.length > 0) {
        if (start == '分页') {
          res.data.data.forEach(item => {
            this.list.push(item)
          });
        }
        this.finished = false;
      } else {

        this.finished = true;
      }
      this.loading = false;
      this.loadStart = false;
    },
    async onLoad () {

      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // 加载状态结束
      this.loading = true;
      setTimeout(() => {
        this.page += 1
        this.get_index('分页')
      }, 1000)
    },
    onClickLeft () {
      this.$router.back()
    },
  }
}
</script>

<style lang="less" scoped>
.van-div {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
  justify-content: space-between;
  border-bottom: 1px dashed #ebedf0;
}
</style>
